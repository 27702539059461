import { BaseModel } from 'modules/core/models/base-model';

/**
 * ID's
 * @type {int}
 */

const SOILS_NT_LAB = 1;

const SOILS_T_LAB = 2;

const AGGREGATES_NT_LAB = 3;

const AGGREGATES_T_LAB = 4;

const SOILS_NT_IN_SITU = 5;

const SOILS_T_IN_SITU = 6;

const AGGREGATES_NT_IN_SITU = 7;

const AGGREGATES_T_IN_SITU = 8;

const BITUMINOUS_MIXTURES_LIGANDS = 9;

const BITUMINOUS_MIXTURES_LAB = 10;

const BITUMINOUS_MIXTURES_IN_SITU = 11;

const CONCRETE = 12;

const BITUMINOUS_MIXTURES_FORMULATION_STUDY = 13;

/**
 * SLUGS
 * @type {string}
 */

const IN_SITU_WORK_CONTROL = 'in_situ_work_control';

const LAB_WORK_CONTROL = 'lab_work_control';

const LAB_USE_IN = 'lab_use_in';

const LAB_MATERIAL_CHARACTERIZATION = 'lab_material_characterization';

export class Sector extends BaseModel {
    id          = null;
    category    = null;
    sticky      = null;
    description = null;

    static getFieldSlugs(id) {
        switch (parseInt(id)) {
            case Sector.SOILS_NT_LAB:
                return [LAB_WORK_CONTROL, LAB_MATERIAL_CHARACTERIZATION];
            case Sector.SOILS_T_LAB:
                return [LAB_WORK_CONTROL, LAB_MATERIAL_CHARACTERIZATION];
            case Sector.AGGREGATES_NT_LAB:
                return [LAB_WORK_CONTROL, LAB_USE_IN, LAB_MATERIAL_CHARACTERIZATION];
            case Sector.AGGREGATES_T_LAB:
                return [LAB_WORK_CONTROL, LAB_USE_IN, LAB_MATERIAL_CHARACTERIZATION];
            case Sector.SOILS_NT_IN_SITU:
                return [IN_SITU_WORK_CONTROL];
            case Sector.SOILS_T_IN_SITU:
                return [IN_SITU_WORK_CONTROL];
            case Sector.AGGREGATES_NT_IN_SITU:
                return [IN_SITU_WORK_CONTROL];
            case Sector.AGGREGATES_T_IN_SITU:
                return [IN_SITU_WORK_CONTROL];
            default:
                return [LAB_WORK_CONTROL, LAB_USE_IN, LAB_MATERIAL_CHARACTERIZATION];
        }
    }

    static get IN_SITU_WORK_CONTROL() {
        return IN_SITU_WORK_CONTROL;
    }

    static get LAB_WORK_CONTROL() {
        return LAB_WORK_CONTROL;
    }

    static get LAB_USE_IN() {
        return LAB_USE_IN;
    }

    static get LAB_MATERIAL_CHARACTERIZATION() {
        return LAB_MATERIAL_CHARACTERIZATION;
    }

    static get SOILS_NT_LAB() {
        return SOILS_NT_LAB;
    }

    static get SOILS_T_LAB() {
        return SOILS_T_LAB;
    }

    static get AGGREGATES_NT_LAB() {
        return AGGREGATES_NT_LAB;
    }

    static get AGGREGATES_T_LAB() {
        return AGGREGATES_T_LAB;
    }

    static get SOILS_NT_IN_SITU() {
        return SOILS_NT_IN_SITU;
    }

    static get SOILS_T_IN_SITU() {
        return SOILS_T_IN_SITU;
    }

    static get AGGREGATES_NT_IN_SITU() {
        return AGGREGATES_NT_IN_SITU;
    }

    static get AGGREGATES_T_IN_SITU() {
        return AGGREGATES_T_IN_SITU;
    }

    static get BITUMINOUS_MIXTURES_LIGANDS() {
        return BITUMINOUS_MIXTURES_LIGANDS;
    }

    static get BITUMINOUS_MIXTURES_LAB() {
        return BITUMINOUS_MIXTURES_LAB;
    }

    static get BITUMINOUS_MIXTURES_IN_SITU() {
        return BITUMINOUS_MIXTURES_IN_SITU;
    }

    static get CONCRETE() {
        return CONCRETE;
    }

    static get BITUMINOUS_MIXTURES_FORMULATION_STUDY() {
        return BITUMINOUS_MIXTURES_FORMULATION_STUDY;
    }
}
